var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"management-report-card-box"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$waiting.is('loading-newCard')),expression:"$waiting.is('loading-newCard')"}]},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('el-input',{staticClass:"break-normal text-base font-semibold pl-2",attrs:{"type":"text","disabled":_vm.localInputDisabled,"placeholder":"Titel"},model:{value:(_vm.localInputText.headTitle),callback:function ($$v) {_vm.$set(_vm.localInputText, "headTitle", $$v)},expression:"localInputText.headTitle"}}),_c('div',{staticClass:"flex items-center ml-2"},[_c('el-button',{attrs:{"title":"Flytta upp","icon":"el-icon-arrow-up","circle":""},on:{"click":function($event){return _vm.moveCardUp()}}}),_c('el-button',{attrs:{"title":"Flytta ned","icon":"el-icon-arrow-down","circle":""},on:{"click":function($event){return _vm.moveCardDown()}}}),_c('el-button',{attrs:{"size":"mini","disabled":_vm.localInputDisabled,"title":_vm.tableButtonTitle,"type":"warning"},on:{"click":_vm.toggleTable}},[_c('i',{staticClass:"fa-regular fa-table-list pr-1"}),(_vm.inputText.addTable == false)?_c('i',{staticClass:"fa-solid fa-plus"}):_c('i',{staticClass:"fa-solid fa-minus"})]),_c('el-button',{attrs:{"title":"Radera","type":"danger","icon":"el-icon-delete","circle":"","disabled":_vm.localInputDisabled},on:{"click":function($event){return _vm.deleteCard()}}})],1)],1),(_vm.inputText.addTable)?_c('el-table',{attrs:{"data":_vm.inputText.tableRows}},[_c('el-table-column',{attrs:{"width":"480"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":_vm.localInputDisabled,"placeholder":"Text"},model:{value:(row.columnOne),callback:function ($$v) {_vm.$set(row, "columnOne", $$v)},expression:"row.columnOne"}})]}}],null,false,3817584928)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":_vm.localInputDisabled,"placeholder":"Text"},model:{value:(row.columnTwo),callback:function ($$v) {_vm.$set(row, "columnTwo", $$v)},expression:"row.columnTwo"}})]}}],null,false,1795851784)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var store = ref.store;
var row = ref.row;
return [(store.states.data.length !== 1)?[_c('el-button',{attrs:{"plain":"","size":"mini","disabled":_vm.localInputDisabled},on:{"click":function($event){return _vm.deleteRowTableList(row)}}},[_c('i',{staticClass:"fa-solid fa-minus"})])]:_vm._e(),($index === store.states.data.length - 1)?[_c('el-button',{attrs:{"plain":"","size":"mini","disabled":_vm.localInputDisabled},on:{"click":_vm.addRowTableList}},[_c('i',{staticClass:"fa-solid fa-plus"})])]:_vm._e()]}}],null,false,1521193662)})],1):_vm._e(),(_vm.toggleValue)?_c('div',[_c('el-input',{staticClass:"break-normal custom-textarea pl-2 pr-2 pt-4",attrs:{"type":"textarea","disabled":_vm.localInputDisabled,"placeholder":"Text"},model:{value:(_vm.localInputText.text),callback:function ($$v) {_vm.$set(_vm.localInputText, "text", $$v)},expression:"localInputText.text"}})],1):_vm._e()],1),_c('CardEditButtons',{attrs:{"toggle-value":_vm.toggleValue,"show-edit-button":_vm.showEditButton,"toggle-edit":_vm.toggleEdit,"close-modal":_vm.closeModal,"save-data":_vm.saveData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }